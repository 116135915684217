<template>
	<!-- style.mode: 0-通用(普通), 1-VIP, 2-通用VIP -->
	<div class="page bg-white relative">
    <img :src="style.memberBgImg1" alt="" class="bg block">

    <div class="content absolute flex flex-direction justify-between align-center">
      
      <div class="top-box">
        <div class="logo flex align-center justify-between">
          <!-- <img :src="style.logo || logoSrc" alt="" :class="[ticketType ? 'img' : 'img1']"> -->
          <img v-if="isCamp" src="https://exhoss37.szcreativeweek.com/common/applet/camp-logo.png" style="width: 120px; height: auto;" />
          <img v-else src="https://exhoss37.szcreativeweek.com/common/applet/logo.png" style="width: 200px; height: auto;" />
          <!-- <span v-if="!isVip" class="ticket-type">{{ticketType || ''}}</span> -->
        </div>
        <div class="title">{{clientTypeName || 'Digital visitor pass'}}</div>
        <div class="name-box">{{customerName || ''}}</div>
      </div>
      <div class="qrcode-container radius" @click="refreshQrCode">
        <vue-qr :text="qrCodeText" :correctLevel="3" :margin="15" colorDark="#000" colorLight="#fff" :dotScale="1"
         :logoSrc="isCamp ? campLogo : qrCodelogo" :logoScale="0.3" :size="180" style="width: 100%;">
        </vue-qr>
      </div>
      
      <div class="tips-content">
        <span>For venue access, use the generated QR code <br/> at all scanning entrance points</span>
        <span>This QR code is for personal use only,</span>
        <span>screenshots and transfers will be invalid</span>
      </div>
      
      <div v-if="isShowGroup" class="group-container">
        <a target="_blank" href="https://www.szcreativeweek.com/register?k=group" class="service-btn block flex align-center justify-center">
          <img :src="groupImg" alt="" class="group-img">
          <span>Group registration</span>
        </a>
        <a target="_blank" href="https://work.weixin.qq.com/u/vcee117faad809dff6?v=4.1.12.141039" class="service-btn block flex align-center justify-center">
          <img :src="serviceImg" alt="" class="service-img">
          <span>Add Assistant</span>
        </a>
      </div>
      
      <div class="footer">
        <bottom :mode="style.mode" :isCamp="isCamp" :level="style.level" language="en" color="#333"></bottom>
      </div>
      
    </div>
    
    <van-popup v-model="payState" class="tips-popup">
      <div class="tips-box en-font">
        <div class="row-tips">Your ticket has expired</div>
      </div>
      <van-button type="primary" block class="font16 radius btn" @click="toPurchase">Repurchase</van-button>
    </van-popup>
		
		
  </div>
</template>

<script>
  import { Popup, Icon } from 'vant';
	import vueQr from "vue-qr";
	import commonMixin from '@/view/process/mixin/common.js'
	import memberCodeMixin from '@/view/process/mixin/memberCode.js'

	export default {
		mixins: [commonMixin, memberCodeMixin],
		components: {
			vueQr,
      [Icon.name]: Icon,
      [Popup.name]: Popup,
		},
		data() {
			return {
				
			}
		},
		created() {
			this.setLanguge('en'); // 设置语言环境
		},
		methods: {
			
		}
	}
</script>

<style lang="less" scoped>
  
  .page {
    border-top: transparent;
  }
  
  .bg {
    width: 100%;
    height: auto;
    opacity: .1;
  }
  
// PC
@media screen and (min-width: 751px) {
  .content {
    width: 100%;
    min-height: 100%;
    overflow-y: auto;
    z-index: 9;
    padding: 40Px 0 20Px;
    box-sizing: border-box;
    
    .top-box {
      width: 640Px;
      margin: 0 auto ;
      color: #000;
      font-family: Source Han Sans CN;
    }
    .logo {
      margin: 4vh auto 2vh;
      
      .img {
        width: 240Px;
      }
      .img1 {
        width: 300Px;
      }
      
      .ticket-type {
        height: 48Px;
        // margin-left: 80px;
        color: #27B3C9;
        font-size: 28Px;
        font-weight: bold;
        border-bottom: 4Px solid;
      }
    }
    
    .title {
      font-size: 40Px;
      font-weight: 500;
      margin-bottom: 12Px;
      letter-spacing: 1Px;
    }
    .name-box {
      font-size: 36Px;
      margin-bottom: 30Px;
      font-weight: normal;
    }
      
    .qrcode-container {
      background-color: #fff;
      margin: 0 auto 30Px;
      // padding: 10px;
      width: 300Px;
      height: 300Px;
      // border: 8px solid #333;
      box-shadow: 0 0 18Px rgba(0, 0, 0, .2);
      cursor: pointer;
    }
    
    .tips-content {
      font-size: 24Px;
      color: #333;
      font-family: 'DIN-Regular';
      margin-bottom: 30Px;
      
      span {
        display: block;
        text-align: center;
        line-height: 44Px;
      }
    }
    
    .group-container {
      
      .service-btn {
        background-color: #1169A3;
        color: #fff;
        border: none;
        width: 360Px;
        height: 72Px;
        font-size: 30Px;
        margin: 0 auto 30Px;
        border-radius: 8Px;
        
        .group-img {
          display: block;
          width: 66Px;
          height: auto;
          margin-right: 10Px;
        }
        
        .service-img {
          display: block;
          width: 50Px;
          height: auto;
          margin-right: 15Px;
        }
        
      }
      
      .group-tips {
        font-size: 28Px;
        transform: scale(0.9);
        line-height: 1.4;
        
        a {
          color: #1169A3;
        }
      }
      
    }
    
  }
  
  
  .btn {
  	width: 240Px;
  	height: 72Px;
  	margin: 0 auto 3vh;
    background-color: #048DAD;
    letter-spacing: 1Px;
    border: none;
  }
  .tips-popup {
    border-radius: 36Px;
  }
  .tips-box {
    width: 540Px;
    height: 120Px;
    color: #888;
    
    .row-tips {
      margin: 60Px auto;
      text-align: center;
      line-height: 60Px;
      font-size: 36Px;
    }
  }
}

// 移动
@media screen and (max-width: 750px) {
  .content {
    width: 100%;
    min-height: 100%;
    overflow-y: auto;
    z-index: 9;
    padding: 18px 0;
    box-sizing: border-box;
    
    .top-box {
      width: 320px;
      margin: 0 auto ;
      color: #000;
      font-family: Source Han Sans CN;
    }
    .logo {
      margin: 4vh auto 2vh;
      
      .img {
        width: 120px;
      }
      .img1 {
        width: 150px;
      }
      
      .ticket-type {
        height: 24px;
        // margin-left: 80px;
        color: #27B3C9;
        font-size: 14px;
        font-weight: bold;
        border-bottom: 2px solid;
      }
    }
    
    .title {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 6px;
      letter-spacing: 1px;
    }
    .name-box {
      font-size: 18px;
      margin-bottom: 6px;
      font-weight: normal;
    }
      
    .qrcode-container {
      background-color: #fff;
      margin: 0 auto 10px;
      // padding: 10px;
      width: 180px;
      height: 180px;
      // border: 8px solid #333;
      box-shadow: 0 0 9px rgba(0, 0, 0, .2);
    }
    
    .tips-content {
      font-size: 12px;
      color: #333;
      // font-family: 'DIN-Regular';
      margin-bottom: 20px;
      
      span {
        display: block;
        text-align: center;
        line-height: 18px;
        // margin-bottom: 2vh;
      }
    }
    
    .group-container {
      
      
      .service-btn {
        background-color: #1169A3;
        color: #fff;
        border: none;
        width: 200px;
        height: 40px;
        font-size: 16px;
        margin: 0 auto 15px;
        border-radius: 4px;
        
        .group-img {
          display: block;
          width: 36px;
          height: auto;
          margin-right: 5px;
        }
        
        .service-img {
          display: block;
          width: 28px;
          height: auto;
          margin-right: 10px;
        }
        
      }
      
      .group-tips {
        font-size: 14px;
        transform: scale(0.9);
        line-height: 1.4;
        
        a {
          color: #1169A3;
        }
      }
      
    }
    
  }
  
  
  .btn {
  	width: 120px;
  	height: 36px;
  	margin: 0 auto 3vh;
    background-color: #048DAD;
    letter-spacing: 1Px;
    border: none;
  }
  .tips-popup {
    border-radius: 18px;
  }
  .tips-box {
    width: 270px;
    height: 60px;
    color: #888;
    
    .row-tips {
      margin: 30px auto;
      text-align: center;
      line-height: 30px;
      font-size: 18px;
    }
  }
}

  
</style>
